.tiptap > *:first-child,
.tiptap-sectionItemColumn > *:first-child {
  @apply mt-0;
}
.tiptap > *,
.tiptap-sectionItemColumn > * {
  @apply mt-24;
}
.tiptap strong {
  @apply font-bold;
}
.tiptap-heading + .collaboration-cursor__empty + .tiptap-heading,
.tiptap-heading + .tiptap-heading {
  @apply mt-16;
}
.tiptap-heading + .collaboration-cursor__empty + *,
.tiptap-heading + * {
  @apply mt-16;
}
h1.tiptap-heading {
  @apply text-docs-title-1 mt-[48px];
}
h2.tiptap-heading {
  @apply text-docs-title-2 mt-36;
}
h3.tiptap-heading {
  @apply text-docs-title-3 mt-24;
}
h4.tiptap-heading {
  @apply text-docs-title-4 mt-24;
}
h5.tiptap-heading {
  @apply text-docs-title-5 mt-24;
}
.tiptap-paragraph {
  @apply text-docs-body;
}

.tiptap-paragraph + .collaboration-cursor__empty + .tiptap-paragraph,
.tiptap-paragraph + .tiptap-paragraph {
  @apply mt-12;
}
.tiptap-list {
  @apply text-docs-body ml-24;
}
.tiptap-list > * {
  @apply mt-8;
}
.tiptap-list > *:first-child {
  @apply mt-0;
}
.tiptap-orderedList {
  @apply !list-decimal;
}
.tiptap-orderedList .tiptap-orderedList {
  @apply !list-[lower-alpha];
}

.tiptap-orderedList .tiptap-orderedList .tiptap-orderedList {
  @apply !list-[lower-roman];
}
.tiptap-orderedList
  .tiptap-orderedList
  .tiptap-orderedList
  .tiptap-orderedList {
  @apply !list-[upper-alpha];
}
.tiptap-orderedList
  .tiptap-orderedList
  .tiptap-orderedList
  .tiptap-orderedList
  .tiptap-orderedList {
  @apply !list-decimal;
}

.tiptap-bulletList {
  @apply !list-[revert];
}
.tiptap-listItem > * {
  @apply mt-8;
}
.tiptap-listItem > *:first-child {
  @apply mt-0;
}

p.is-empty:not(.block-selection)::before,
/* This applies when there's no block selection on a heading */
.tiptap-heading.is-empty:not(.block-selection)::before {
  @apply text-neutral-placeholder pointer-events-none float-left max-h-0 content-[attr(data-placeholder)];
}

/*
This applies special behavior to inline-editor, so the placeholder isn't overflowing to the next line.
*/
.inline-editor p.is-empty:not(.block-selection),
.inline-editor .tiptap-heading.is-empty:not(.block-selection) {
  @apply relative;
}

.inline-editor p.is-empty:not(.block-selection)::before,
/* This applies when there's no block selection on a heading */
.inline-editor .tiptap-heading.is-empty:not(.block-selection)::before {
  @apply absolute bottom-0 left-0 right-0 top-0 float-none max-h-none overflow-hidden;
}

/*
This applies when there's block selection on a heading.
Since block selection applies a border, we have to deduce its size in left and right, thus 3 and
7 px are used.
*/
.tiptap-heading.is-empty.block-selection::before {
  @apply text-neutral-placeholder pointer-events-none px-[7px] py-[3px] content-[attr(data-placeholder)];
}
.node-callout.is-empty > div > div > div::before,
.node-blockquote.is-empty > div > div > div::before {
  @apply pointer-events-none float-left max-h-0 content-[var(--data-placeholder)];
}

.tiptap-callout.type-accent {
  color: var(--look-and-feel-accent-color, var(--rs-color-foreground-primary));
  background-color: var(
    --look-and-feel-accent-surface-color,
    var(--rs-color-background-primary-faded)
  );
  border-color: var(
    --look-and-feel-accent-surface-color,
    var(--rs-color-border-primary-faded)
  );
}

.collaboration-cursor__caret {
  @apply pointer-events-none relative -ml-1 -mr-1 break-normal border-l-[1px] border-r-[1px];
}

/* Render the username label above the caret */
.collaboration-cursor__caret .collaboration-cursor__label {
  @apply text-body-small absolute -left-1 -top-24 select-none whitespace-nowrap rounded-t rounded-br py-4 pl-[6px] pr-8 font-semibold;
}

.node-blockNode > div::before,
.node-image > div::before {
  @apply -z-2 rounded-medium bg-elevation-base absolute -bottom-8 -left-8 -right-8 -top-8 content-[""];
}

.tabsSection::before {
  @apply -z-2 rounded-medium absolute -bottom-8 -left-8 -right-8 -top-8 content-[""];
}

.tiptap-link {
  @apply underline underline-offset-4;
  color: var(--look-and-feel-accent-color, var(--rs-color-foreground-primary));

  &:hover {
    @apply underline underline-offset-4;
  }
}

.tiptap-code {
  @apply text-decorative-red rounded-small border-neutral-faded border bg-neutral-highlighted p-2 font-mono text-[0.825em] font-bold leading-[1px];
}

.tiptap h1.tiptap-heading .tiptap-code {
  @apply px-8;
}
.tiptap h2.tiptap-heading .tiptap-code {
  @apply px-[6px];
}

.tiptap-selection-area {
  background: rgba(46, 115, 252, 0.11);
  border: 2px solid rgba(98, 155, 255, 0.81);
  border-radius: 0.1em;
}
.tiptap-selection-area-container {
  @apply !z-20;
}

.tiptap-marquee-selection #documentation-scroll-area {
  @apply !cursor-default;
}

.tiptap-marquee-selection #documentation-scroll-area * {
  @apply !pointer-events-none !cursor-default;
}

div[data-radix-popper-content-wrapper]:has(.lb-emoji-picker, .lb-tooltip),
div[data-liveblocks-portal]:has(
    .lb-composer-suggestions,
    .lb-composer-mention-suggestions
  ) {
  @apply !z-50;
}

.tippy-content,
.tippy-box {
  @apply bg-transparent p-0;
}
