.page-loader-logo-animation {
  transform-origin: 30px 30px;
  stroke-dasharray: 42;
  animation: page-loader-logo-animation 5000ms infinite ease-in-out;
  animation-fill-mode: forwards;
  will-change: transform;
}

@keyframes page-loader-logo-animation {
  0% {
    transform: rotate(0deg);
  }
  80%, 100% {
    transform: rotate(-720deg);
  }
}

.path1 {
  animation: trim1 5000ms infinite ease-in-out;
  animation-fill-mode: forwards;
  will-change: stroke-dashoffset;
}

@keyframes trim1 {
  0% {
    stroke-dashoffset: 0;
  }
  28%, 100% {
    stroke-dashoffset: 42;
  }
}

.path2 {
  animation: trim2 5000ms infinite ease-in-out;
  animation-fill-mode: forwards;
  will-change: stroke-dashoffset;
}

@keyframes trim2 {
  8% {
    stroke-dashoffset: 0;
  }
  36%, 100% {
    stroke-dashoffset: 42;
  }
}

.path3 {
  animation: trim3 5000ms infinite ease-in-out;
  animation-fill-mode: forwards;
  will-change: stroke-dashoffset;
}

@keyframes trim3 {
  16% {
    stroke-dashoffset: 0;
  }
  44%, 100% {
    stroke-dashoffset: 42;
  }
}

.path4 {
  animation: trim4 5000ms infinite ease-in-out;
  animation-fill-mode: forwards;
  will-change: stroke-dashoffset;
}

@keyframes trim4 {
  24% {
    stroke-dashoffset: 0;
  }
  52%, 100% {
    stroke-dashoffset: 42;
  }
}

.path5 {
  animation: trim5 5000ms infinite ease-in-out;
  animation-fill-mode: forwards;
  will-change: stroke-dashoffset;
}

@keyframes trim5 {
  0%, 30% {
    stroke-dashoffset: 42;
  }
  55%, 100% {
    stroke-dashoffset: 0;
  }
}

.path6 {
  animation: trim6 5000ms infinite ease-in-out;
  animation-fill-mode: forwards;
  will-change: stroke-dashoffset;
}

@keyframes trim6 {
  0%, 36% {
    stroke-dashoffset: 42;
  }
  61%, 100% {
    stroke-dashoffset: 0;
  }
}

.path7 {
  animation: trim7 5000ms infinite ease-in-out;
  animation-fill-mode: forwards;
  will-change: stroke-dashoffset;
}

@keyframes trim7 {
  0%, 42% {
    stroke-dashoffset: 42;
  }
  67%, 100% {
    stroke-dashoffset: 0;
  }
}

.path8 {
  animation: trim8 5000ms infinite ease-in-out;
  animation-fill-mode: forwards;
  will-change: stroke-dashoffset;
}

@keyframes trim8 {
  0%, 48% {
    stroke-dashoffset: 42;
  }
  73%, 100% {
    stroke-dashoffset: 0;
  }
}
