.block-selection {
  @apply relative z-0 caret-[transparent];
}

.block-selection.ProseMirror-selectednode {
  @apply z-2;
}

.block-selection::before {
  @apply -z-2 bg-primary-faded border-primary-faded border rounded-medium absolute -bottom-4 -left-8 -right-8 -top-4 cursor-default content-[""];
}

.node-blockquote.block-selection::before,
tablecontainer.block-selection::before {
  @apply -bottom-8 -top-8;
}

.node-callout.block-selection::before {
  @apply border-primary-faded -bottom-8 -top-8 border;
}
.tiptap-list.block-selection::before {
  @apply -left-32;
}
.tiptap-listItem.block-selection::before {
  @apply -left-32;
}

.node-blockNode.block-selection > div::before,
.node-image.block-selection > div::before {
  @apply bg-primary-faded -z-2 rounded-medium border-primary-faded absolute cursor-default border content-[""];
}

.node-tabsSection.block-selection::before {
  @apply rounded-b-large -bottom-8 bg-primary-faded border border-primary-faded;
}

.block-selection::selection,
.block-selection
  *:not(input, textarea, .inline-editor *, .rich-text-editor *)::selection {
  @apply bg-transparent;
}

.node-blockNode.block-selection,
.node-image.block-selection {
  @apply caret-[auto];
}

/* Custom solution to show caret nested editors during block (item) selection */
.inline-editor *,
.rich-text-editor * {
  @apply caret-[var(--rs-color-foreground-neutral)];
}
/* Custom solution to hide selection in block (item) selection without impacting nested editors */
.block-selection:not(
    .inline-editor *::selection,
    .rich-text-editor *::selection
  ) {
  @apply bg-transparent;
}
/* Custom solution to hide selection in nested editors unless they are focused */
.block-selection .inline-editor.hide-editor-selection *::selection,
.block-selection .rich-text-editor.hide-editor-selection *::selection {
  @apply bg-transparent;
}

/* This eliminates unnecesary second selection indicator caused by transparent background-primary-faded */
.ProseMirror-selectednode::before {
  @apply bg-transparent border-0;
}

.node-callout.block-selection:before {
  @apply bg-primary-faded;
}
