@import "react-complex-tree/lib/style.css";

:root {
  --rct-color-tree-bg: transparent;

  --rct-color-focustree-item-selected-bg: var(--rs-color-background-primary-faded);
  --rct-color-focustree-item-focused-border: transparent;

  --rct-color-nonfocustree-item-selected-bg: var(--rs-color-background-primary-faded);
  --rct-color-nonfocustree-item-selected-text: inherit;
  --rct-color-nonfocustree-item-focused-border: transparent;
  --rct-item-height: 22px;
  --rct-color-tree-focus-outline: inherit;

  /*
    Fallback value. Actual value is provided by react-complex-tree on the element itself.
     */
  --depthOffset: 0px;
}

.rct-tree-root {
  font-family: inherit;
}

.rct-tree-item-title-container {
    padding-left: 0;
    margin-top: 4px;
    margin-bottom: 4px;
}


.rct-tree-item-title-container .rct-tree-item-button {
    /* Adjust indent padding. Each item has a basic padding of 4px.
    And we need to reduce each padding by 10px to make items look like in the specs.
    */
  padding-left: calc(var(--depthOffset, 0px) - 10px + 4px);
  margin-top: 4px;
  margin-bottom: 4px;
}

.rct-tree-item-li {
  font-size: var(--rs-font-size-body-2);
}

/**
    * This class is used to add ellipsis to text in the file tree.
 */
 .file-tree-text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limits to one line in WebKit browsers */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: clip;
  max-width: 100%;
  white-space: normal;

  /* Fallback for non-WebKit browsers like Firefox */
  line-height: 1.5em;
  max-height: 1.5em;
}

.rct-tree-item-title-container-focused {
  background-color: var(--rs-color-background-neutral);
}

.rct-tree-item-title-container:not(.rct-tree-item-title-container-focused):not(
    .rct-tree-item-title-container-selected
  ):hover {
  background-color: var(--rs-color-background-neutral); 
  border-radius: var(--rs-unit-radius) !important;
}

.rct-tree-items-container {
  padding: 0 8px !important;
}

.rct-tree-item-title-container-selected {
  color: var(--rs-color-foreground-primary) !important;
  border-radius: var(--rs-unit-radius) !important;
}

.rct-tree-item-title-container-selected .item-title {
  color: var(--rs-color-foreground-primary);
}

.monaco-editor .find-widget {
  background-color: #151d37 !important;
}

.monaco-editor, .monaco-editor-background {
    background-color: var(--rs-color-background-page-faded) !important;
  }

.monaco-editor .margin {
  background-color: var(--rs-color-background-page-faded) !important;
}

.monaco-editor .minimap-decorations-layer {
  background-color: var(--rs-color-background-page-faded) !important;
  mix-blend-mode: lighten;
}

/* hack to style the decorations overview ruler */
.decorationsOverviewRuler {
  padding-right: 14px !important;
  background-color:  var(--rs-color-background-page-faded) !important;
}

.monaco-editor .find-widget .vertical {
  box-shadow: 2px 0 0 0 var(--rs-color-border-primary) inset !important;
}

.monaco-editor .find-widget .monaco-inputbox {
  background-color: #202943 !important;
}

.monaco-editor .find-widget textarea {
  color: var(--rs-color-white) !important;
}

.monaco-editor .find-widget textarea::placeholder,
.monaco-editor .find-widget .controls,
.monaco-editor .find-widget .find-actions,
.monaco-editor .find-widget > .button.codicon-widget-close {
  color: var(--rs-color-foreground-neutral-faded) !important;
}

.monaco-editor .find-widget .matchesCount {
  color: var(--rs-color-white) !important;
}

.monaco-editor .find-widget.no-results .matchesCount {
  color: var(--rs-color-foreground-warning) !important;
}

.monaco-editor-overlaymessage .message {
  background-color: #1f2942 !important;
  font-size: var(--rs-font-size-body-3) !important;
  font-weight: var(--rs-font-weight-body-3) !important;
  line-height: var(--rs-line-height-body-3) !important;
  color: var(--rs-color-foreground-neutral) !important;
}

.bg-checkered-preview {
  --checkered-canvas-bg: #ebebeb;
  --checkered-square: #fafafa;
  --checkered-square-size: 16px;
  background-image: linear-gradient(
      45deg,
      var(--checkered-square) 25%,
      transparent 25%,
      transparent 75%,
      var(--checkered-square) 75%,
      var(--checkered-square) 100%
    ),
    linear-gradient(
      45deg,
      var(--checkered-square) 25%,
      var(--checkered-canvas-bg) 25%,
      var(--checkered-canvas-bg) 75%,
      var(--checkered-square) 75%,
      var(--checkered-square) 100%
    );
  background-size: var(--checkered-square-size) var(--checkered-square-size);
  background-position: 0 0,
    calc(var(--checkered-square-size) / 2)
      calc(var(--checkered-square-size) / 2);
}
