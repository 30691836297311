/* We have to have shadows defined like this, because otherwise they don't work */
.shadow-figma {
  box-shadow: 0 20px 40px 0 rgba(9, 18, 42, 0.08);
}

.shadow-state-field {
  box-shadow: 0px 20px 40px 0px rgba(9, 18, 42, 0.08);
}

@keyframes import-onboarding-pulsing-animation {
  0% {
    height: 100%;
    width: 100%;
    opacity: 0.08;
  }
  99% {
    height: 400%;
    width: 400%;
    opacity: 0;
  }
}

@keyframes import-onboarding-connecting-animation {
  0% {
    transform: translateY(-100%);
  }
  60%,
  100% {
    transform: translateY(100%);
  }
}
