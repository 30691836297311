/*
 * Take font differences out of equation for inline Skeletons that just wrap text.
 * This makes the dimensions consistent between the following non-Skeleton and Skeleton text:
 * ```
 * <Text>Example text</Text>
 *
 * <Text>
 *   <Skeleton>Example text</Skeleton>
 * </Text>
 * ```
 *
 * Will NOT collapse Skeletons as the browsers use line-height on inline elements just for alignment.
 */
.dm-skeleton:where([data-inline-skeleton]) {
    line-height: 0;
    /* Font metrics on Arial yield much nicer height for the text background bounding box */
    font-family: Arial, sans-serif !important;
}

.dm-skeleton:not([class*="rounded"]) {
   @apply rounded
}

:where(.dm-skeleton:empty) {
    display: block;
}

.dm-skeleton > *,
.dm-skeleton::after,
.dm-skeleton::before {
    visibility: hidden !important;
}
