.tableWrapper {
  @apply max-w-full overflow-x-auto;
}

.tableWrapper .tiptap-paragraph {
  @apply text-docs-body-medium;
}

.tableWrapper table {
  @apply mb-8 border-collapse overflow-hidden;
}
.tableWrapper td,
.tableWrapper th {
  @apply h-table-group-cell relative box-border min-w-[36px] max-w-[1600px] break-words border border-[#00216f08] px-12 py-8 text-left align-top;
}

.bordered-table td,
.bordered-table th {
  @apply border-neutral-faded;
}

.hovered-borders td,
.hovered-borders th {
  @apply border-neutral-faded;
}

.tableWrapper th {
  @apply bg-neutral-faded text-left;
}

.tableWrapper th .tiptap-paragraph,
.tableWrapper td .tiptap-paragraph {
  @apply break-words;
}

.tableWrapper th .tiptap-paragraph {
  @apply font-bold;
}

.tableWrapper .node-image {
  @apply m-8 max-w-md;
}

.tableWrapper img.ProseMirror-selectednode {
  @apply border-primary-faded border-2;
}

:not(.tiptap-marquee-selection) .column-resize-handle {
  @apply bg-primary-highlighted pointer-events-none absolute -bottom-2 -right-2 top-0 z-10 w-[3px];
}

:not(.tiptap-marquee-selection) .resize-cursor {
  @apply cursor-col-resize;
}

.selectedCell:after {
  @apply bg-primary z-2 pointer-events-none absolute bottom-0 left-0 right-0 top-0 opacity-10 content-[''];
}
