@keyframes profile-onboarding-show-job-department-animation {
  0% {
    transform: translate(0px, 60px);
    opacity: 0;
  }
  100% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}

@keyframes profile-onboarding-show-developer-base-animation {
  0% {
    transform: translate(0px, 60px);
    opacity: 0;
    height: 0px;
  }
  100% {
    transform: translate(0px, 0px);
    opacity: 1;
    height: 124px;
  }
}

@keyframes profile-onboarding-show-company-name-svg-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes profile-onboarding-hide-company-name-svg-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes profile-onboarding-show-company-name-animation {
  0% {
    padding: 0px;
    width: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0);
    transform: translate(0px, 0px) scale(1);
    backdrop-filter: blur(4px);
    box-shadow: 0px 0px 0px 0px rgba(20, 23, 31, 0.2);
  }
  100% {
    padding: 24px 8px 24px 8px;
    width: 160px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    transform: translate(-20px, -20px) scale(1.4);
    backdrop-filter: blur(8px);
    box-shadow: 0px 20px 40px 0px rgba(20, 23, 31, 0.2);
  }
}

@keyframes profile-onboarding-hide-company-name-animation {
  0% {
    padding: 24px 8px 24px 8px;
    width: 160px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    transform: translate(-20px, -20px) scale(1.4);
    backdrop-filter: blur(8px);
    box-shadow: 0px 20px 40px 0px rgba(20, 23, 31, 0.2);
  }
  100% {
    padding: 0px;
    width: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0);
    transform: translate(0px, 0px) scale(1);
    backdrop-filter: blur(4px);
    box-shadow: 0px 0px 0px 0px rgba(20, 23, 31, 0.2);
  }
}

.profile-onboarding-show-job-department-animation {
  opacity: 0;
  animation-name: profile-onboarding-show-job-department-animation;
  animation-duration: 800ms;
  animation-fill-mode: forwards;
}

.profile-onboarding-show-developer-base-animation {
  opacity: 0;
  height: 0;
  animation-name: profile-onboarding-show-developer-base-animation;
  animation-duration: 800ms;
  animation-fill-mode: forwards;
}
